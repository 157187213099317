<!-- Row -->
<form [formGroup]="form">
  <div class="form-group row">
    <label
      [nbTooltip]="tooltip ? tooltip : ''"
      nbTooltipPlacement="top"
      nbTooltipStatus="info"
      [ngStyle]="tooltip ? {'text-decoration': 'underline', 'text-decoration-style': 'dotted'} : {}"
      class="label col-sm-3 col-form-label"
    >
      {{ title ?? 'String Array' }}</label
    >
    <div class="col-sm-9">
      <ng-select
        [items]="items"
        [multiple]="true"
        [formControlName]="controlName"
        [hideSelected]="true"
        [addTag]="addTag"
        [isOpen]="false"
        class="hide-arrow hide-clear-all"
        [placeholder]="placeholder ?? 'Type to add new items (type tag:tag_name to add a RAW_TEXT TagData)'"
      >
        <ng-template ng-label-tmp let-item="item" let-clear="clear">
          <span class="ng-value-label">
            <nb-icon *ngIf="item.tag" icon="tag" pack="fas"></nb-icon>
            {{ item.tag ?? item }}
          </span>
          <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">x</span>
        </ng-template>
      </ng-select>
    </div>
  </div>
</form>
