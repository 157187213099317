<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"
        style="background-color: black; border-radius: 8px; padding: 2px; color: white"></nb-icon>
    </a>
    <a class="logo" href="#" (click)="navigateHome()" style="color: white; font-weight: bold; font-family: fantasy"><img
        src="/assets/images/surfboard.png" />¡Otro maravilloso día en el paraíso!</a>
  </div>
  <h1 *ngIf="!isEnviromenteProd" style="color: white">DEVELOPMENT</h1>
  <h1 *ngIf="isEnviromenteProd" style="margin-left: 20px" [style.color]="textColor ?? 'white'">{{ customMessage }}</h1>
</div>

<div class="header-container">
  <nb-actions size="small" style="background-color: black; border-radius: 3rem; padding: 7px">
    <nb-action (click)="hardRefresh()" class="control-item" icon="sync"></nb-action>

    <!-- <nb-action class="control-item">
      <nb-search type="column-curtain"></nb-search>
    </nb-action>
    <nb-action class="control-item" icon="email-outline"></nb-action>
    <nb-action class="control-item" icon="bell-outline"></nb-action> -->
    <nb-action *ngIf="user" class="user-action">
      <!-- <img getURL(user?.image)/> -->
      <img class="profileImg" crossorigin="anonymous" [src]="getURL(user?.image)" alt="asdasd" />
      <nb-user [nbContextMenu]="userMenu" nbContextMenuTag="my-context-menu" [onlyPicture]="userPictureOnly"
        [name]="user?.name"> </nb-user>
    </nb-action>
  </nb-actions>
</div>