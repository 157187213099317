<nb-card>
  <nb-card-header>{{ title }}</nb-card-header>
  <nb-card-body>
    <div *ngIf="isTagsEditor" class="form-group row">
      <label class="label col-sm-3 col-form-label">Tags</label>
      <div class="col-sm-9">
        <ng-select
          [items]="tags_options"
          [multiple]="true"
          [closeOnSelect]="false"
          [hideSelected]="true"
          placeholder="Select tags"
          [(ngModel)]="zones"
          dropdownPosition="bottom"
          fullWidth
        >
        </ng-select>
      </div>
    </div>
    <form [formGroup]="languageForm">
      <div class="form-group" formArrayName="Zones">
        <div  *ngFor="let zone of this.getZones().controls; let zIndex = index" [formGroupName]="zIndex">
          <div class="mb-3 row" style="width: 100%; display: flex; gap: 20px; align-items: center" *ngIf="zones.includes(zone.get('zoneName').value)">
            <h5 class="col-3">{{ zone.get('zoneName').value }}</h5>
            <div style="flex: 1" class="col-9" class="form-group" formArrayName="Languages">
              <div class="row" *ngFor="let lang of getLanguages(zIndex).controls; let i = index" [formGroupName]="i" class="mb-3 row">
                <nb-form-field class="col-10 formContainer" style="width: 100%; max-width: 100% !important">
                  <nb-icon nbPrefix [icon]="lang.value.icon" pack="flags" style="font-size: 1.2rem !important"></nb-icon>
                  <input type="text" fullWidth nbInput formControlName="text" [placeholder]="getPlaceholder(lang.value.languageCode)" />
                </nb-form-field>
                <div class="col-2" nbButton (click)="translateText(i, zIndex)">
                  <svg style="width: 35px" version="1.1" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 998.1 998.3" xml:space="preserve">
                    <path fill="#DBDBDB" d="M931.7 998.3c36.5 0 66.4-29.4 66.4-65.4V265.8c0-36-29.9-65.4-66.4-65.4H283.6l260.1 797.9h388z" />
                    <path
                      fill="#DCDCDC"
                      d="M931.7 230.4c9.7 0 18.9 3.8 25.8 10.6 6.8 6.7 10.6 15.5 10.6 24.8v667.1c0 9.3-3.7 18.1-10.6 24.8-6.9 6.8-16.1 10.6-25.8 10.6H565.5L324.9 230.4h606.8m0-30H283.6l260.1 797.9h388c36.5 0 66.4-29.4 66.4-65.4V265.8c0-36-29.9-65.4-66.4-65.4z"
                    />
                    <polygon fill="#4352B8" points="482.3,809.8 543.7,998.3 714.4,809.8" />
                    <path
                      fill="#607988"
                      d="M936.1 476.1V437H747.6v-63.2h-61.2V437H566.1v39.1h239.4c-12.8 45.1-41.1 87.7-68.7 120.8-48.9-57.9-49.1-76.7-49.1-76.7h-50.8s2.1 28.2 70.7 108.6c-22.3 22.8-39.2 36.3-39.2 36.3l15.6 48.8s23.6-20.3 53.1-51.6c29.6 32.1 67.8 70.7 117.2 116.7l32.1-32.1c-52.9-48-91.7-86.1-120.2-116.7 38.2-45.2 77-102.1 85.2-154.2H936v.1z"
                    />
                    <path fill="#4285F4" d="M66.4 0C29.9 0 0 29.9 0 66.5v677c0 36.5 29.9 66.4 66.4 66.4h648.1L454.4 0h-388z" />
                    <linearGradient id="a" gradientUnits="userSpaceOnUse" x1="534.3" y1="433.2" x2="998.1" y2="433.2">
                      <stop offset="0" stop-color="#fff" stop-opacity=".2" />
                      <stop offset="1" stop-color="#fff" stop-opacity=".02" />
                    </linearGradient>
                    <path fill="url(#a)" d="M534.3 200.4h397.4c36.5 0 66.4 29.4 66.4 65.4V666L534.3 200.4z" />
                    <path
                      fill="#EEEEEE"
                      d="M371.4 430.6c-2.5 30.3-28.4 75.2-91.1 75.2-54.3 0-98.3-44.9-98.3-100.2s44-100.2 98.3-100.2c30.9 0 51.5 13.4 63.3 24.3l41.2-39.6c-27.1-25-62.4-40.6-104.5-40.6-86.1 0-156 69.9-156 156s69.9 156 156 156c90.2 0 149.8-63.3 149.8-152.6 0-12.8-1.6-22.2-3.7-31.8h-146v53.4l91 .1z"
                    />
                    <radialGradient id="b" cx="65.208" cy="19.366" r="1398.271" gradientUnits="userSpaceOnUse">
                      <stop offset="0" stop-color="#fff" stop-opacity=".1" />
                      <stop offset="1" stop-color="#fff" stop-opacity="0" />
                    </radialGradient>
                    <path
                      fill="url(#b)"
                      d="M931.7 200.4H518.8L454.4 0h-388C29.9 0 0 29.9 0 66.5v677c0 36.5 29.9 66.4 66.4 66.4h415.9l61.4 188.4h388c36.5 0 66.4-29.4 66.4-65.4V265.8c0-36-29.9-65.4-66.4-65.4z"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div class="divider"></div>
          </div>
        </div>
      </div>
    </form>
  </nb-card-body>
  <nb-card-footer style="display: flex; align-items: center; justify-content: space-between">
    <div>
      <button nbButton status="primary" (click)="save()">Save</button>
      <button nbButton ghost status="primary" (click)="dismiss()">Cancel</button>
      <button nbButton ghost status="danger" (click)="clear()">Empty</button>
    </div>
  </nb-card-footer>
</nb-card>
