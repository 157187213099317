import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {
  NbActionsModule,
  NbLayoutModule,
  NbMenuModule,
  NbSearchModule,
  NbSidebarModule,
  NbUserModule,
  NbContextMenuModule,
  NbButtonModule,
  NbSelectModule,
  NbCheckboxModule,
  NbPopoverModule,
  NbIconModule,
  NbThemeModule,
  NbFormFieldModule,
  NbInputModule,
  NbCardModule,
  NbSpinnerModule,
  NbTooltipModule,
  NbToggleModule,
  NbDatepickerModule,
  NbListModule,
  NbAutocompleteModule,
  NbAlertModule,
} from '@nebular/theme';
import {NbDateFnsDateModule} from '@nebular/date-fns';
import {NbEvaIconsModule} from '@nebular/eva-icons';
import {NbSecurityModule} from '@nebular/security';

import {
  FooterComponent,
  HeaderComponent,
  LayoutDirectionSwitcherComponent,
  SearchInputComponent,
  SwitcherComponent,
  JSONInputComponent,
  EditServiceDialogComponent,
  EditAmendDialogComponent,
  AudioInputComponent,
  EditStringTagDataArrayComponent,
  TTSEditorComponent,
  STTEditorComponent,
  ScheduleEditorComponent,
  ScheduleEditorLegacyComponent,
  KeywordPopupComponent,
  NewCustomerDialogComponent,
  NewVenueDialogComponent,
  ZoneJSONInput,
  ZoneEditorDialogComponent,
} from './components';
import {CapitalizePipe, PluralPipe, RoundPipe, TimingPipe, NumberWithCommasPipe, SafeUrlPipe} from './pipes';
import {EmptyLayoutComponent, OneColumnLayoutComponent, ThreeColumnsLayoutComponent, TwoColumnsLayoutComponent} from './layouts';
import {DEFAULT_THEME} from './styles/theme.default';
import {COSMIC_THEME} from './styles/theme.cosmic';
import {CORPORATE_THEME} from './styles/theme.corporate';
import {DARK_THEME} from './styles/theme.dark';
import {LanguageSelectorDialogComponent} from './components/json-input/laguage-selector/language-selector-dialog.component';
import {LanguageValueStringComponent} from './components/language-value-string/language-value-string.component';
import {LanguageMapStringEditorDialogComponent} from './components/language-value-string/language-map-string-editor/language-map-string-editor-dialog.component';
import {AudioGeneratorDialogComponent} from './components/audio-input/audio-generator/audio-generator-dialog.component';
import {MatSliderModule} from '@angular/material/slider';
import {ImageInputComponent} from './components/image-input/image-input.component';
import {NgSelectModule} from '@ng-select/ng-select';
import {SelectValueFromListDialogComponent} from './components/select-value-from-list-dialog/select-value-from-list-dialog.component';
import {EditCreateIntegrationDialogComponent} from './components/edit-create-integration-dialog/edit-create-integration-dialog.component';
import {NewVenueGroupDialogComponent} from './components/new-venue-group/new-venue-group-dialog.component';
import {TemplateChutatorComponent} from './components/template-chutator/template-chutator.component';

const NB_MODULES = [
  NbLayoutModule,
  NbMenuModule,
  NbUserModule,
  NbActionsModule,
  NbSearchModule,
  NbPopoverModule,
  NbSidebarModule,
  NbContextMenuModule,
  NbSecurityModule,
  NbButtonModule,
  NbSelectModule,
  NbCheckboxModule,
  NbIconModule,
  NbEvaIconsModule,
  NbInputModule,
  NbCardModule,
  NbSpinnerModule,
  NbFormFieldModule,
  NbTooltipModule,
  NbToggleModule,
  MatSliderModule,
  NbDatepickerModule,
  NbListModule,
  NbDateFnsDateModule,
  NbAutocompleteModule,
  NbAlertModule,
];
const NG_MODULES = [NgSelectModule];
const COMPONENTS = [
  SwitcherComponent,
  LayoutDirectionSwitcherComponent,
  HeaderComponent,
  FooterComponent,
  SearchInputComponent,
  EmptyLayoutComponent,
  OneColumnLayoutComponent,
  ThreeColumnsLayoutComponent,
  TwoColumnsLayoutComponent,
  JSONInputComponent,
  ZoneEditorDialogComponent,
  ZoneJSONInput,
  AudioInputComponent,
  LanguageSelectorDialogComponent,
  LanguageMapStringEditorDialogComponent,
  LanguageValueStringComponent,
  AudioGeneratorDialogComponent,
  ImageInputComponent,
  EditServiceDialogComponent,
  EditAmendDialogComponent,
  EditStringTagDataArrayComponent,
  SelectValueFromListDialogComponent,
  EditCreateIntegrationDialogComponent,
  TTSEditorComponent,
  STTEditorComponent,
  ScheduleEditorComponent,
  ScheduleEditorLegacyComponent,
  KeywordPopupComponent,
  NewCustomerDialogComponent,
  NewVenueDialogComponent,
  NewVenueGroupDialogComponent,
  TemplateChutatorComponent,
];
const PIPES = [CapitalizePipe, PluralPipe, RoundPipe, TimingPipe, NumberWithCommasPipe, SafeUrlPipe];

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, ...NB_MODULES, ...NG_MODULES],
  exports: [CommonModule, ...PIPES, ...COMPONENTS],
  declarations: [...COMPONENTS, ...PIPES],
})
export class ThemeModule {
  static forRoot(): ModuleWithProviders<ThemeModule> {
    return <ModuleWithProviders<ThemeModule>>{
      ngModule: ThemeModule,
      providers: [
        ...NbThemeModule.forRoot(
          {
            name: 'default',
          },
          [DEFAULT_THEME, COSMIC_THEME, CORPORATE_THEME, DARK_THEME]
        ).providers,
      ],
    };
  }
}
