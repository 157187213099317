import {Component, Input, OnInit, OnDestroy} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, UntypedFormArray, Validators} from '@angular/forms';
import {NbDialogRef} from '@nebular/theme';
import {NbToastrService} from '@nebular/theme';
import {TranslateService} from '../../../../@core/api/translate.service';
import {LanguageEnum, LanguageEnumCodeMap} from '../../../../@core/data/model-utils.model';

@Component({
  selector: 'ngx-zone-editor-dialog',
  templateUrl: './zone-editor-dialog.component.html',
  styleUrls: ['zone-editor-dialog.component.scss'],
})
export class ZoneEditorDialogComponent implements OnInit, OnDestroy {
  @Input() title: string;
  @Input() supported_languages: string[];
  @Input() zones: string[]; // List of zones
  @Input() placeholdersData?: any;
  @Input() value: string;
  @Input() skip_validation: boolean;
  @Input() languageEnum: boolean;
  @Input() isStrArray: boolean;
  @Input() isTagsEditor: boolean;

  languageForm: UntypedFormGroup;
  tags_options: string[] = [
    'SELECTED_RESTAURANT',
    'CONTACT_PHONE',
    'CONTACT_EMAIL',
    'RESTAURANT_NAME',
    'RESTAURANT_ADDRESS',
    'VOUCHER_LINK',
    'LOCATION_LINK',
    'MENU_LINK',
    'DELIVERY_LINK',
    'GROUP_BOOKING_LINK',
  ];

  constructor(protected ref: NbDialogRef<ZoneEditorDialogComponent>, private fb: UntypedFormBuilder, private toastrService: NbToastrService, private ts: TranslateService) {}

  ngOnInit() {
    if (this.isTagsEditor) {
      const prefill = this.value ? JSON.parse(this.value) : {};
      // this.zones = this.tags_options
      this.zones = this.tags_options.filter((tag) => prefill[tag]);
      this.languageForm = this.fb.group({
        Zones: this.fb.array(this.tags_options.map((zone) => this.createZoneFormGroup(zone))),
      });
    } else {
      this.languageForm = this.fb.group({
        Zones: this.fb.array(this.zones.map((zone) => this.createZoneFormGroup(zone))),
      });
    }
    // if (this.isTagsEditor) {
    //   this.zones = [];
    // }

    // Prepopulate with existing data, if any provided
    if (this.value) {
      this.setJSON(this.value);
    }
  }

  private languageFlagIcon(languageCode: string): string {
    switch (languageCode) {
      case 'ca-ES':
        return 'es-ct';
      case 'en-US':
        return 'us';
      case 'en-GB':
        return 'gb';
      case 'fr-FR':
        return 'fr';
      case 'de-DE':
        return 'de';
      case 'es-MX':
        return 'mx';
      case 'es-UY':
        return 'uy';
      case 'es-PY':
        return 'py';
      case 'es-CL':
        return 'cl';
      case 'es-CO':
        return 'co';
      case 'es-ES':
        return 'es';
      case 'it-IT':
        return 'it';
      case 'pt-PT':
        return 'pt';
      case 'ar-SA':
        return 'sa';
      case 'tr-TR':
        return 'tr';
      case 'ro-RO':
        return 'ro';
      case 'nb-NO':
        return 'no';
      default:
        return 'es';
    }
  }

  setJSON(value: string) {
    try {
      const parsedValue = JSON.parse(value);

      if (this.isTagsEditor) {
        this.zones = Object.keys(parsedValue);
      }

      // Recorrer todas las zonas (keys) del objeto JSON
      this.getZones().controls.forEach((zoneControl, zoneIndex) => {
        const zoneName = zoneControl.get('zoneName').value;
        const zoneData = parsedValue[zoneName]; // Obtener los datos de la zona correspondiente

        // Verificar si existen datos para la zona
        if (zoneData) {
          // Recorrer todos los idiomas de la zona
          this.getLanguages(zoneIndex).controls.forEach((langControl, langIndex) => {
            let languageCode = langControl.get('languageCode').value;
            if (this.languageEnum) {
              languageCode = Object.keys(LanguageEnumCodeMap).find((key) => LanguageEnumCodeMap[key] === languageCode) || languageCode;
            }
            const translatedText = zoneData[languageCode];

            // Si existe texto para el idioma, asignarlo al campo
            if (translatedText !== undefined) {
              langControl.get('text').setValue(translatedText);
            } else {
              // Si no hay texto para el idioma, asignar un valor por defecto
              langControl.get('text').setValue('');
            }
          });
        }
      });
    } catch (error) {
      console.error('Error al establecer los datos JSON:', error);
      this.toastrService.show('Error', 'Hubo un error al cargar los datos JSON.', {status: 'danger'});
    }
  }

  createZoneFormGroup(zone: string): UntypedFormGroup {
    return this.fb.group({
      zoneName: [zone],
      Languages: this.fb.array(this.supported_languages.map((lang) => this.createLanguageFormGroup(lang))),
    });
  }

  createLanguageFormGroup(languageCode: string): UntypedFormGroup {
    return this.fb.group({
      languageCode: [languageCode],
      text: ['', Validators.required],
      icon: [this.languageFlagIcon(languageCode)],
    });
  }

  getZones() {
    return this.languageForm.get('Zones') as UntypedFormArray;
  }

  getLanguages(zoneIndex: number) {
    return this.getZones().at(zoneIndex).get('Languages') as UntypedFormArray;
  }

  getPlaceholder(lang: string) {
    if (this.placeholdersData) {
      const jsonData = JSON.parse(this.placeholdersData);
      if (jsonData && jsonData[lang]) {
        return jsonData[lang];
      }
    }
    return '';
  }

  async translateText(languageIndex: number, zoneIndex: number) {
    const formArray = this.getLanguages(zoneIndex); // Obtener el FormArray de idiomas de la zona actual
    const currentLang = formArray.at(languageIndex); // Obtener el control del idioma específico
    const textToTranslate = currentLang.get('text').value; // El texto a traducir

    // Si el texto está vacío, no continuar
    if (!textToTranslate) {
      this.toastrService.show('Error', 'El texto a traducir no puede estar vacío.', {status: 'danger'});
      return;
    }

    // Expresión regular para capturar variables en formato $VARIABLE y URLs
    const variableRegex = /\$[A-Z_]+/g;
    const urlRegex = /(https?:\/\/[^\s]+|www\.[^\s]+)/g; // Para URLs que empiezan con http, https o www.

    let variableMap = new Map<string, string>();
    let urlMap = new Map<string, string>();
    let counter = 1; // Contador para los placeholders numéricos

    // Proteger variables en formato $VARIABLE
    let protectedText = textToTranslate.replace(variableRegex, (match) => {
      const placeholder = `<span class="notranslate">$${counter}</span>`;
      variableMap.set(placeholder, match);
      counter++;
      return placeholder;
    });

    // Proteger URLs
    protectedText = protectedText.replace(urlRegex, (match) => {
      const placeholder = `<span class="notranslate">URL_${counter}</span>`;
      urlMap.set(placeholder, match);
      counter++;
      return placeholder;
    });

    try {
      // Traducir el texto para todos los demás idiomas en la zona actual
      for (let i = 0; i < formArray.length; i++) {
        if (i !== languageIndex) {
          // Evitar traducir el idioma ya seleccionado
          const targetField = formArray.at(i).get('text'); // Obtener el campo de traducción del idioma

          // Si el campo está vacío o indefinido, proceder con la traducción
          if (targetField.value === '' || targetField.value === undefined) {
            const targetLangCode = formArray.at(i).get('languageCode').value; // Obtener el código de idioma del idioma de destino
            const response = await this.ts.translateText(protectedText, targetLangCode).toPromise(); // Llamar al servicio de traducción
            let translatedText = response.data.translations[0].translatedText; // Obtener el texto traducido

            // Restaurar las variables protegidas
            translatedText = translatedText.replace(/<span class="notranslate">\$?\d+<\/span>/g, (placeholder) => variableMap.get(placeholder));

            // Restaurar URLs protegidas
            translatedText = translatedText.replace(/<span class="notranslate">URL_\d+<\/span>/g, (placeholder) => urlMap.get(placeholder));

            targetField.setValue(translatedText); // Asignar el texto traducido al campo correspondiente
          }
        }
      }
    } catch (error) {
      console.error('Error de traducción:', error);
      this.toastrService.show('Translation Error', error.message, {status: 'danger'});
    }
  }
  filterKeys(obj, allowedKeys) {
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        // Si el key no está en allowedKeys, eliminarlo
        if (!allowedKeys.includes(key)) {
          delete obj[key];
        }
      }
    }
    return obj;
  }

  async save() {
    const data = await this.getJSON();

    this.ref.close(data);
  }

  async getJSON() {
    const jsonData = {};

    for (let zIndex = 0; zIndex < this.getZones().length; zIndex++) {
      const zoneForm = this.getZones().at(zIndex);
      const zoneName = zoneForm.get('zoneName').value;
      const languages = zoneForm.get('Languages').value;

      let zoneData = {};
      languages.forEach((lang) => {
        zoneData[lang.languageCode] = lang.text;
      });

      if (this.languageEnum) {
        const enumJsonValues = {};
        for (const key in zoneData) {
          const enumKey = Object.keys(LanguageEnumCodeMap).find((enumKey) => LanguageEnumCodeMap[enumKey] === key);
          if (enumKey) {
            enumJsonValues[enumKey] = zoneData[key];
          }
        }
        zoneData = enumJsonValues;
      }

      jsonData[zoneName] = zoneData;
    }

    const cleanedData = this.filterKeys(jsonData, this.zones);

    return JSON.stringify(cleanedData);
  }

  dismiss() {
    this.ref.close(null);
  }

  clear() {
    this.ref.close('');
  }
  close() {
    this.ref.close();
  }

  ngOnDestroy() {}
}
