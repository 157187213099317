import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ControlContainer, UntypedFormBuilder, UntypedFormGroup, FormGroupDirective} from '@angular/forms';
import {NbDialogService} from '@nebular/theme';
import {TagData} from '../../../@core/data/model-utils.model';

// https://stackoverflow.com/questions/52893088/forwarding-formcontrolname-to-inner-component-in-angular-with-reactive-forms
@Component({
  selector: 'ngx-edit-string-tagdata-array',
  styleUrls: ['./edit-string-tagdata-array.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
  templateUrl: './edit-string-tagdata-array.component.html',
})
export class EditStringTagDataArrayComponent implements OnInit, OnChanges {
  @Input() formGroup: UntypedFormGroup;
  @Input() controlName: string;
  @Input() title?: string;
  @Input() placeholder?: string;
  @Input() tooltip?: string;

  form: UntypedFormGroup;
  items: (string | TagData)[];
  watchFormGroupChanges: boolean = false;
  constructor(public controlContainer: ControlContainer) {}

  ngOnInit() {
    // Set reference to form
    if (this.formGroup != null) {
      this.form = this.formGroup;
      this.watchFormGroupChanges = true;
    } else {
      this.form = <UntypedFormGroup>this.controlContainer.control;
    }
    // Set items list
    this.items = this.form.get(this.controlName).value;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.watchFormGroupChanges) {
      for (const propertyName in changes) {
        if (propertyName == 'formGroup') {
          this.ngOnInit();
          break;
        }
      }
    }
  }

  addTag(new_value: string): string | TagData {
    // Process data
    let new_data: string | TagData = new_value;
    if (new_value.startsWith('tag:')) {
      new_data = {tag: new_value.substring(4), entity_type: 'RAW_TEXT'};
    }
    // Update items list
    if (!this.items.includes(new_data)) {
      this.items = [...this.items, new_data];
    }
    return new_data;
  }
}
