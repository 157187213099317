export const LanguageEnumOptions = [
  'SPANISH',
  'CATALAN',
  'MEXICAN',
  'URUGUAYAN',
  'PARAGUAYAN',
  'CHILEAN',
  'ENGLISH',
  'GERMAN',
  'FRENCH',
  'PORTUGUESE',
  'BRITISH',
  'ITALIAN',
  'COLOMBIAN',
  'SAUDIARABIAN',
  'TURKISH',
  'ROMANIAN',
  'NORWEGIAN',
  'DUTCH',
] as const;

export type LanguageEnum = typeof LanguageEnumOptions[number];

export type LanguageMap<T> = Partial<Record<LanguageEnum, T>>;

export type LanguageValue<T> = T | LanguageMap<T>;

export const EntityTypeOptions = ['RAW_TEXT', 'KEYWORD', 'DATE', 'DATETIME', 'TIME', 'NUMBER', 'NAME', 'CONFIRMATION', 'REGEX', 'PHONE_NUMBER'] as const;

export type EntityType = typeof EntityTypeOptions[number];

export const LanguageEnumFlagIconMap: Record<LanguageEnum, string> = {
  SPANISH: 'es',
  CATALAN: 'es-ct',
  MEXICAN: 'mx',
  URUGUAYAN: 'uy',
  COLOMBIAN: 'co',
  ENGLISH: 'us',
  GERMAN: 'de',
  FRENCH: 'fr',
  PORTUGUESE: 'pt',
  BRITISH: 'gb',
  ITALIAN: 'it',
  SAUDIARABIAN: 'sa',
  TURKISH: 'tr',
  ROMANIAN: 'ro',
  NORWEGIAN: 'no',
  PARAGUAYAN: 'py',
  CHILEAN: 'cl',
  DUTCH: 'nl',
};

export const LanguageEnumCodeMap: Record<LanguageEnum, string> = {
  SPANISH: 'es-ES',
  URUGUAYAN: 'es-UY',
  PARAGUAYAN: 'es-PY',
  CHILEAN: 'es-CL',
  CATALAN: 'ca-ES',
  MEXICAN: 'es-MX',
  ENGLISH: 'en-US',
  GERMAN: 'de-DE',
  FRENCH: 'fr-FR',
  PORTUGUESE: 'pt-PT',
  BRITISH: 'en-GB',
  NORWEGIAN: 'nb-NO',
  ITALIAN: 'it-IT',
  COLOMBIAN: 'es-CO',
  SAUDIARABIAN: 'ar-SA',
  TURKISH: 'tr-TR',
  ROMANIAN: 'ro-RO',
  DUTCH: 'nl-NL',
};

/** Model to dynamically use tag data at runtime in flexbot models. */
export interface TagData {
  tag: string;
  entity_type: EntityType;
}

interface PhoneNumberSchema {
  e164: string;
  country_code: string;
  national: string;
}

export type PhoneNumber = string | PhoneNumberSchema;

export interface ShiftTime {
  hour: number;
  minute: number;
  carry_over: boolean;
}

export interface Shift {
  start: ShiftTime;
  end: ShiftTime;
  frequency?: number;
}

export interface Schedule {
  monday: Shift[];
  tuesday: Shift[];
  wednesday: Shift[];
  thursday: Shift[];
  friday: Shift[];
  saturday: Shift[];
  sunday: Shift[];
}

export const countries = [
  {code: 'ES', name: 'Spain'},
  {code: 'PT', name: 'Portugal'},
  {code: 'AS', name: 'American Samoa'},
  {code: 'AD', name: 'Andorra'},
  {code: 'AO', name: 'Angola'},
  {code: 'AI', name: 'Anguilla'},
  {code: 'AQ', name: 'Antarctica'},
  {code: 'AG', name: 'Antigua and Barbuda'},
  {code: 'AR', name: 'Argentina'},
  {code: 'AM', name: 'Armenia'},
  {code: 'AW', name: 'Aruba'},
  {code: 'AU', name: 'Australia'},
  {code: 'AT', name: 'Austria'},
  {code: 'AZ', name: 'Azerbaijan'},
  {code: 'BS', name: 'Bahamas'},
  {code: 'BH', name: 'Bahrain'},
  {code: 'BD', name: 'Bangladesh'},
  {code: 'BB', name: 'Barbados'},
  {code: 'BY', name: 'Belarus'},
  {code: 'BE', name: 'Belgium'},
  {code: 'BZ', name: 'Belize'},
  {code: 'BJ', name: 'Benin'},
  {code: 'BM', name: 'Bermuda'},
  {code: 'BT', name: 'Bhutan'},
  {code: 'BO', name: 'Bolivia'},
  {code: 'BA', name: 'Bosnia and Herzegovina'},
  {code: 'BW', name: 'Botswana'},
  {code: 'BV', name: 'Bouvet Island'},
  {code: 'BR', name: 'Brazil'},
  {code: 'IO', name: 'British Indian Ocean Territory'},
  {code: 'BN', name: 'Brunei Darussalam'},
  {code: 'BG', name: 'Bulgaria'},
  {code: 'BF', name: 'Burkina Faso'},
  {code: 'BI', name: 'Burundi'},
  {code: 'KH', name: 'Cambodia'},
  {code: 'CM', name: 'Cameroon'},
  {code: 'CA', name: 'Canada'},
  {code: 'CV', name: 'Cape Verde'},
  {code: 'KY', name: 'Cayman Islands'},
  {code: 'CF', name: 'Central African Republic'},
  {code: 'TD', name: 'Chad'},
  {code: 'CL', name: 'Chile'},
  {code: 'CN', name: 'China'},
  {code: 'CX', name: 'Christmas Island'},
  {code: 'CC', name: 'Cocos (Keeling) Islands'},
  {code: 'CO', name: 'Colombia'},
  {code: 'KM', name: 'Comoros'},
  {code: 'CG', name: 'Congo'},
  {code: 'CD', name: 'Congo, Democratic Republic'},
  {code: 'CK', name: 'Cook Islands'},
  {code: 'CR', name: 'Costa Rica'},
  {code: 'CI', name: "Côte d'Ivoire"},
  {code: 'HR', name: 'Croatia'},
  {code: 'CU', name: 'Cuba'},
  {code: 'CY', name: 'Cyprus'},
  {code: 'CZ', name: 'Czech Republic'},
  {code: 'DK', name: 'Denmark'},
  {code: 'DJ', name: 'Djibouti'},
  {code: 'DM', name: 'Dominica'},
  {code: 'DO', name: 'Dominican Republic'},
  {code: 'EC', name: 'Ecuador'},
  {code: 'EG', name: 'Egypt'},
  {code: 'SV', name: 'El Salvador'},
  {code: 'GQ', name: 'Equatorial Guinea'},
  {code: 'ER', name: 'Eritrea'},
  {code: 'EE', name: 'Estonia'},
  {code: 'ET', name: 'Ethiopia'},
  {code: 'FK', name: 'Falkland Islands (Malvinas)'},
  {code: 'FO', name: 'Faroe Islands'},
  {code: 'FJ', name: 'Fiji'},
  {code: 'FI', name: 'Finland'},
  {code: 'FR', name: 'France'},
  {code: 'GF', name: 'French Guiana'},
  {code: 'PF', name: 'French Polynesia'},
  {code: 'TF', name: 'French Southern Territories'},
  {code: 'GA', name: 'Gabon'},
  {code: 'GM', name: 'Gambia'},
  {code: 'GE', name: 'Georgia'},
  {code: 'DE', name: 'Germany'},
  {code: 'GH', name: 'Ghana'},
  {code: 'GI', name: 'Gibraltar'},
  {code: 'GR', name: 'Greece'},
  {code: 'GL', name: 'Greenland'},
  {code: 'GD', name: 'Grenada'},
  {code: 'GP', name: 'Guadeloupe'},
  {code: 'GU', name: 'Guam'},
  {code: 'GT', name: 'Guatemala'},
  {code: 'GG', name: 'Guernsey'},
  {code: 'GN', name: 'Guinea'},
  {code: 'GW', name: 'Guinea-Bissau'},
  {code: 'GY', name: 'Guyana'},
  {code: 'HT', name: 'Haiti'},
  {code: 'HM', name: 'Heard Island and McDonald Islands'},
  {code: 'VA', name: 'Holy See (Vatican City State)'},
  {code: 'HN', name: 'Honduras'},
  {code: 'HK', name: 'Hong Kong'},
  {code: 'HU', name: 'Hungary'},
  {code: 'IS', name: 'Iceland'},
  {code: 'IN', name: 'India'},
  {code: 'ID', name: 'Indonesia'},
  {code: 'IR', name: 'Iran, Islamic Republic of'},
  {code: 'IQ', name: 'Iraq'},
  {code: 'IE', name: 'Ireland'},
  {code: 'IM', name: 'Isle of Man'},
  {code: 'IL', name: 'Israel'},
  {code: 'IT', name: 'Italy'},
  {code: 'JM', name: 'Jamaica'},
  {code: 'JP', name: 'Japan'},
  {code: 'JE', name: 'Jersey'},
  {code: 'JO', name: 'Jordan'},
  {code: 'KZ', name: 'Kazakhstan'},
  {code: 'KE', name: 'Kenya'},
  {code: 'KI', name: 'Kiribati'},
  {code: 'KR', name: 'Korea, Republic of'},
  {code: 'KP', name: "Korea, Democratic People's Republic of"},
  {code: 'KW', name: 'Kuwait'},
  {code: 'KG', name: 'Kyrgyzstan'},
  {code: 'LA', name: "Lao People's Democratic Republic"},
  {code: 'LV', name: 'Latvia'},
  {code: 'LB', name: 'Lebanon'},
  {code: 'LS', name: 'Lesotho'},
  {code: 'LR', name: 'Liberia'},
  {code: 'LY', name: 'Libya'},
  {code: 'LI', name: 'Liechtenstein'},
  {code: 'LT', name: 'Lithuania'},
  {code: 'LU', name: 'Luxembourg'},
  {code: 'MO', name: 'Macao'},
  {code: 'MK', name: 'Macedonia, the former Yugoslav Republic of'},
  {code: 'MG', name: 'Madagascar'},
  {code: 'MW', name: 'Malawi'},
  {code: 'MY', name: 'Malaysia'},
  {code: 'MV', name: 'Maldives'},
  {code: 'ML', name: 'Mali'},
  {code: 'MT', name: 'Malta'},
  {code: 'MH', name: 'Marshall Islands'},
  {code: 'MQ', name: 'Martinique'},
  {code: 'MR', name: 'Mauritania'},
  {code: 'MU', name: 'Mauritius'},
  {code: 'YT', name: 'Mayotte'},
  {code: 'MX', name: 'Mexico'},
  {code: 'FM', name: 'Micronesia, Federated States of'},
  {code: 'MD', name: 'Moldova, Republic of'},
  {code: 'MC', name: 'Monaco'},
  {code: 'MN', name: 'Mongolia'},
  {code: 'ME', name: 'Montenegro'},
  {code: 'MS', name: 'Montserrat'},
  {code: 'MA', name: 'Morocco'},
  {code: 'MZ', name: 'Mozambique'},
  {code: 'MM', name: 'Myanmar'},
  {code: 'NA', name: 'Namibia'},
  {code: 'NR', name: 'Nauru'},
  {code: 'NP', name: 'Nepal'},
  {code: 'NL', name: 'Netherlands'},
  {code: 'AN', name: 'Netherlands Antilles'},
  {code: 'NC', name: 'New Caledonia'},
  {code: 'NZ', name: 'New Zealand'},
  {code: 'NI', name: 'Nicaragua'},
  {code: 'NE', name: 'Niger'},
  {code: 'NG', name: 'Nigeria'},
  {code: 'NU', name: 'Niue'},
  {code: 'NF', name: 'Norfolk Island'},
  {code: 'MP', name: 'Northern Mariana Islands'},
  {code: 'NO', name: 'Norway'},
  {code: 'OM', name: 'Oman'},
  {code: 'PK', name: 'Pakistan'},
  {code: 'PW', name: 'Palau'},
  {code: 'PS', name: 'Palestinian Territory, Occupied'},
  {code: 'PA', name: 'Panama'},
  {code: 'PG', name: 'Papua New Guinea'},
  {code: 'PY', name: 'Paraguay'},
  {code: 'PE', name: 'Peru'},
  {code: 'PH', name: 'Philippines'},
  {code: 'PN', name: 'Pitcairn'},
  {code: 'PL', name: 'Poland'},
  {code: 'PR', name: 'Puerto Rico'},
  {code: 'QA', name: 'Qatar'},
  {code: 'RE', name: 'Réunion'},
  {code: 'RO', name: 'Romania'},
  {code: 'RU', name: 'Russian Federation'},
  {code: 'RW', name: 'Rwanda'},
  {code: 'BL', name: 'Saint Barthélemy'},
  {code: 'SH', name: 'Saint Helena, Ascension and Tristan da Cunha'},
  {code: 'KN', name: 'Saint Kitts and Nevis'},
  {code: 'LC', name: 'Saint Lucia'},
  {code: 'MF', name: 'Saint Martin (French part)'},
  {code: 'PM', name: 'Saint Pierre and Miquelon'},
  {code: 'VC', name: 'Saint Vincent and the Grenadines'},
  {code: 'WS', name: 'Samoa'},
  {code: 'SM', name: 'San Marino'},
  {code: 'ST', name: 'Sao Tome and Principe'},
  {code: 'SA', name: 'Saudi Arabia'},
  {code: 'SN', name: 'Senegal'},
  {code: 'RS', name: 'Serbia'},
  {code: 'SC', name: 'Seychelles'},
  {code: 'SL', name: 'Sierra Leone'},
  {code: 'SG', name: 'Singapore'},
  {code: 'SK', name: 'Slovakia'},
  {code: 'SI', name: 'Slovenia'},
  {code: 'SB', name: 'Solomon Islands'},
  {code: 'SO', name: 'Somalia'},
  {code: 'ZA', name: 'South Africa'},
  {code: 'GS', name: 'South Georgia and the South Sandwich Islands'},
  {code: 'LK', name: 'Sri Lanka'},
  {code: 'SD', name: 'Sudan'},
  {code: 'SR', name: 'Suriname'},
  {code: 'SJ', name: 'Svalbard and Jan Mayen'},
  {code: 'SZ', name: 'Swaziland'},
  {code: 'SE', name: 'Sweden'},
  {code: 'CH', name: 'Switzerland'},
  {code: 'SY', name: 'Syrian Arab Republic'},
  {code: 'TW', name: 'Taiwan, Province of China'},
  {code: 'TJ', name: 'Tajikistan'},
  {code: 'TZ', name: 'Tanzania, United Republic of'},
  {code: 'TH', name: 'Thailand'},
  {code: 'TL', name: 'Timor-Leste'},
  {code: 'TG', name: 'Togo'},
  {code: 'TK', name: 'Tokelau'},
  {code: 'TO', name: 'Tonga'},
  {code: 'TT', name: 'Trinidad and Tobago'},
  {code: 'TN', name: 'Tunisia'},
];
